<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{$t('commons.actions.new')}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-container>

            <v-row>
              <v-col cols="8">
                <v-text-field
                    v-model="push.title"
                    :rules="requiredRules"
                    :label="$t('pushnotifications.fields.title')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <countries
                    :country.sync="push.country"
                    :required-rules="requiredRules"
                ></countries>
              </v-col>

            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                    v-model="push.text"
                    :rules="requiredRules"
                    :label="$t('pushnotifications.fields.text')"
                    rows="3"
                    required
                    outlined
                    dense
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  v-model="push.action"
                  :items="actions"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('pushnotifications.fields.action')"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <image-upload id="push_notification_image"
                              :image.sync="push.image"
                              btn-label="image"
                              :label="$t('commons.image')"
                ></image-upload>
              </v-col>
              <v-col>
                <image-upload id="push_notification_icon"
                              :image.sync="push.icon"
                              btn-label="icon"
                              :label="$t('commons.icon')"
                ></image-upload>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="store"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>

  import clonedeep from 'lodash.clonedeep'
  import DialogButtons from "@/components/form/DialogButtons";
  import ImageUpload from "@/components/form/ImageUpload";
  import Countries from "@/components/form/Countries";
  import {mapGetters} from "vuex";
  import {EventBus} from "@/event-bus";

  export default {
    name: "Form",

    components: {
      DialogButtons,
      ImageUpload,
      Countries
    },

    data: (self) => ({
      push: {},
      actions: ['home','coupons','missions','profile'],
      defaultPush: {
        title: '',
        text: '',
        action: '',
        country: '',
        image: {
          id: ''
        },
        icon: {
          id: ''
        }
      },
      isEditing: false,
      dialog: false,
      sending: false,
      valid: false,
      requiredRules: [
        v => (v !== '' || !!v) || self.$t('commons.errors.validation.required'),
      ],
    }),

    mounted() {

      let self = this

      this.push = clonedeep(this.defaultPush)

      EventBus.$on('edit-push-notification', function (push) {
        self.push = JSON.parse(JSON.stringify(push))
        self.isEditing = true
        self.dialog = true
      })
    },

    computed: {
      ...mapGetters({
        isLoading: 'pushNotification/loadingState'
      }),
      formTitle () {
        return (this.isEditing === true) ? this.$t('pushnotifications.list.edit') : this.$t('pushnotifications.list.create')
      },
    },

    methods: {
      async store() {
        const valid = await this.$refs.form.validate()

        if (valid) {
          if (!this.isEditing) {
            await this.$store.dispatch('pushNotification/store', this.push)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          } else {
            await this.$store.dispatch('pushNotification/update', this.push)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          }
        }
      },

      close () {
        this.dialog = false
        this.valid = true
        this.$refs.form.reset()
        this.$nextTick(() => {
          this.isEditing = false
          this.push = clonedeep(this.defaultPush)
        })
      },
    }
  }
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>