<template>
    <div>
       <v-data-table
            :loading="loading"
            :loading-text="$t('commons.loading')"
            :headers="headers"
            :items="push"
            :items-per-page="pagination.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable"
        >

         <template v-slot:item.content="{ item }">
           <strong>{{item.title}}</strong><br>
           {{item.text}}
         </template>

         <template v-slot:item.actions="{ item }">
           <v-icon
               small
               class="mr-2"
               @click="edit(item)"
           >
             mdi-pencil
           </v-icon>
         </template>
         <template v-slot:item.sender="{ item }">
           <v-icon
               small
               class="mr-2"
               @click="send(item)"
           >
             mdi-send
           </v-icon>
         </template>

        </v-data-table>

        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="5"
                @input="getList()"
                :disabled="isLoading"
            ></v-pagination>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {EventBus} from "@/event-bus";

    export default {
        name: "List",

        data() {
            return {
                avatarFilter: '',
                filterMenu: false,
                filters: {
                    exact: {},
                    like: {}
                },
                headers: [
                  { text: '#', value: 'id', sortable: false, width: '5%'},
                //  { text: this.$t('commons.fields.icon'), value: 'icon', sortable: false, width: '100px'},
                  { text: this.$t('commons.fields.image'), value: 'image', sortable: false, width: '100px'},
                  { text: this.$t('commons.fields.action'), value: 'action', sortable: false},
                  { text: this.$t('commons.fields.content'), value: 'content', sortable: false},
                  { text: this.$t('commons.fields.country'), value: 'country', sortable: false, width: "5%"},
                  { text: 'edit', value: 'actions', sortable: false},
                  { text: 'send', value: 'sender', sortable: false},
                ]
            }
        },

        components: {
        },

        mounted() {
          this.getList()
        },

        methods: {
            getList() {
              this.$store.dispatch('pushNotification/fetchAll', this.filters)
            },

          edit (push) {
            console.log('edit pushNotification ' , push)
            this.isEditing = true
            EventBus.$emit('edit-push-notification', push)
            this.dialog = true
          },

          send (push) {
            console.log('send pushNotification ' , push)
            this.isSending = true
            EventBus.$emit('sender-push-notification', push)
            this.dialogSender = true
          },
        },

        computed: {
            ...mapGetters({
                isLoading: 'pushNotification/loadingState',
                push: 'pushNotification/getPushNotifications',
                pagination: 'pushNotification/getPagination'
            }),

            loading: {
                get() {
                    return this.isLoading
                },
                set(v) {
                    this.$store.dispatch('pushNotification/setLoadingStatus', v)
                }
            },
        }
    }
</script>

<style scoped>

</style>
