<template>
  <v-dialog
      v-model="dialogSender"
      max-width="800px"
      persistent
  >
    <v-card>
      <v-card-title>
        <p>
          <b>{{ push.title }}</b><br>
          {{ push.text }}
        </p>
      </v-card-title>
        <v-divider></v-divider>
        <v-card-text
         v-if="message"
        >
           <v-alert type="success" class="my-4">
              {{message}}
           </v-alert>
        </v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          v-if="!message"
      >

        <v-card-text>
          <v-row>
            <v-col>
               <app-version-select
                        :value.sync="app_version"
                        :label="$t('commons.fields.min_app_version')"
                ></app-version-select>
            </v-col>
            <v-col cols="12">
              <p>
                UTC time: {{ this.sender.body.send_at }}
              </p>
            </v-col>
            <v-col cols="6">
              <v-date-picker
                  v-model="sender.send_at_date"
                  :min="sender.min_date"
                  @change="changeSendAt"
              >
              </v-date-picker>
            </v-col>
            <v-col cols="6">
              <v-time-picker
                  v-model="sender.send_at_time"
                  format="24hr"
                  @change="changeSendAt"
              >
              </v-time-picker>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="tester"
                @change="isTester(tester)"
                :label="$t('pushnotifications.fields.email')"
              ></v-checkbox>
                      </v-col>
            <v-col cols="8" v-if="tester">
              <v-text-field
                  v-model="testerEmail"
                  @change="addWheres('email','=',testerEmail)"
                  :label="$t('pushnotifications.fields.email')"
                  required
                  outlined
                  dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
            <v-btn
                block
                :disabled="!valid"
                color="success"
                @click="send"
            >
              SUBMIT
            </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="dialogSender = false"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import {EventBus} from "@/event-bus";
import moment from 'moment';
import {mapGetters} from "vuex";
import AppVersionSelect from "@/components/form/AppVersionSelect";


export default {
  name: "Sender",
  components:{
    AppVersionSelect
  },
  computed: {
      ...mapGetters({
            loading: 'pushNotification/loadingState',
            message: 'pushNotification/message',
      }),
  },
  data: () => ({
    dialogSender: false,
    valid: false,
    tester: false,
    app_version: null,
    testerEmail: null,
    wheres: [],
    push: {},
    sender: {
      min_date: moment().utc().add(10, 'minutes').format('YYYY-MM-DD'),
      body: {send_at: moment().utc().add(10, 'minutes').format('YYYY-MM-DD HH:mm')},
      send_at_date: moment().utc().add(10, 'minutes').format('YYYY-MM-DD'),
      send_at_time: moment().utc().add(10, 'minutes').format('HH:mm'),
    }
  }),
  mounted() {
    let self = this
    EventBus.$on('sender-push-notification', function (push) {
      self.push = JSON.parse(JSON.stringify(push))
      self.dialogSender = true
      self.sender.send_at = moment().utc().add(10, 'minutes').format('YYYY-MM-DD HH:mm')
    })
    //this.addWheres('app_version','=',this.app_version)
  },
  watch:{
    'app_version': function(val){
      this.addWheres('app_version','=',this.app_version)
    }
  },
  methods: {
    changeSendAt() {
      this.sender.body.send_at = this.sender.send_at_date + ' ' + this.sender.send_at_time
      this.sender.body.push_id = this.push.id
    },
    async send() {
      const valid = await this.$refs.form.validate()

      if (valid) {
        if (confirm('conferma invio della push: "' + this.push.title + '" ?')) {
          this.sender.body.push_id = this.push.id
          
          this.sender.body.wheres = this.wheres

          await this.$store.dispatch('pushNotification/send', this.sender.body)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                //alert(resp.data.message)
                //this.senderclose()
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        } else {
          this.senderclose()
        }

      }
    },
    senderclose() {
      this.dialogSender = false
    },
    isTester(val){
      if(!val){
        let obj = this.wheres.find(o => o.field === 'email');
        this.wheres = this.wheres.filter(function(ele){ 
            return ele != obj; 
        });
        console.log('WHERES',this.wheres)
      }
    },
    addWheres(field,is,value){     
        let obj = this.wheres.find(o => o.field === field);
        console.log('remove same obj',obj);
        this.wheres = this.wheres.filter(function(ele){ 
            return ele != obj; 
        });    
       this.wheres.push({'type':'and','field':field,'is':is,'value':value});
       console.log('WHERES',this.wheres)
    }

  },


}
</script>
<style scoped>

</style>