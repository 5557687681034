<template>
  <v-sheet class="elevation-3 pa-5">

      <push-form></push-form>
      <push-list></push-list>
      <push-sender></push-sender>
      

    </v-sheet>

</template>

<script>

  import List from "@/components/pushNotifications/List";
  import Form from "@/components/pushNotifications/Form";
  import Sender from "@/components/pushNotifications/Sender";

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
            'push-list': List,
            'push-form': Form,
            'push-sender': Sender,
        }
    }
</script>

<style lang="scss" scoped>
</style>
